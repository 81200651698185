/**
 * @generated SignedSource<<20dc591c5046915626f425cf2b070948>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type sellPageQueries_asset$data = ReadonlyArray<{
  readonly acceptOfferDisabled: {
    readonly __typename: "AcceptOfferDisabledType";
  } | null;
  readonly assetContract: {
    readonly address: string;
  };
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly collection: {
    readonly isCreatorFeesEnforced: boolean;
    readonly isVerified: boolean;
    readonly name: string;
    readonly nativePaymentAsset: {
      readonly symbol: string;
    };
    readonly slug: string;
    readonly statsV2: {
      readonly floorPrice: {
        readonly unit: string;
        readonly usd: string;
      } | null;
    };
    readonly totalCreatorFeeBasisPoints: number;
  };
  readonly decimals: number | null;
  readonly displayImageUrl: string | null;
  readonly includesSharedStorefront: boolean;
  readonly isCurrentlyFungible: boolean;
  readonly name: string | null;
  readonly ownedQuantity: string | null;
  readonly relayId: string;
  readonly tokenId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetItem_asset" | "AssetMedia_asset" | "AssetSellPreviewFooter_assets" | "CollectionPriceButtons_asset" | "ConfirmationItem_assets" | "SellFees_data" | "asset_display_name" | "asset_url">;
  readonly " $fragmentType": "sellPageQueries_asset";
}>;
export type sellPageQueries_asset$key = ReadonlyArray<{
  readonly " $data"?: sellPageQueries_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"sellPageQueries_asset">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "sellPageQueries_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includesSharedStorefront",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        }
      ],
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentlyFungible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AcceptOfferDisabledType",
      "kind": "LinkedField",
      "name": "acceptOfferDisabled",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionStatsV2Type",
          "kind": "LinkedField",
          "name": "statsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "floorPrice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentAssetType",
          "kind": "LinkedField",
          "name": "nativePaymentAsset",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVerified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCreatorFeesEnforced",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCreatorFeeBasisPoints",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMedia_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetSellPreviewFooter_assets"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "asset_url",
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "asset_display_name",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetItem_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmationItem_assets"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionPriceButtons_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellFees_data"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};
})();

(node as any).hash = "c14920eb14547dcd19549d341cac24e9";

export default node;
